<template>
  <div class="h5-main">
    <van-nav-bar
        title="用户登录"
        left-text="返回"
        right-text=""
        left-arrow
        @click-left="onBack"
    />
    <div class="height-5"></div>
    <van-tabs type="card" background="#efefef" color="#1C86EE">
      <van-tab title="账号登录" title-style="height:60px;font-size:18px;">
        <van-form @submit="onNameLogin">
          <van-field
              v-model="loginModel.username"
              name="用户名"
              label="用户名"
              left-icon="manager"
              placeholder="用户名"
              :rules="[{ required: true, message: '请填写用户名' }]"
          />
          <van-field
              v-model="loginModel.password"
              type="password"
              name="密码"
              label="密码"
              left-icon="lock"
              clearable
              placeholder="密码"
              :rules="[{ required: true, message: '请填写密码' }]"
          />
          <van-field
              v-model="loginModel.verifyCode"
              center
              clearable
              label="验证码"
              left-icon="lock"
              placeholder="验证码"
              :rules="[{ required: true, message: '请输入验证码' }]"
          >
            <template #button>
              <van-image
                  width="100"
                  height="30"
                  @click="refreshCode"
                  :src="imageCodeUrl"
              />
            </template>
          </van-field>
          <div style="margin: 16px;">
            <van-button round block type="info" native-type="submit">登录</van-button>
          </div>
        </van-form>
      </van-tab>
      <van-tab title="手机验证码登录" title-style="height:60px;font-size:18px;">
        <van-form @submit="onPhoneLogin">
          <van-field
              v-model="loginModel.phone"
              type="digit"
              name="手机号码"
              label="手机号码"
              left-icon="graphic"
              placeholder="手机号码"
              :rules="[{ validator, message: '' }]"
          />
          <van-field
              v-model="loginModel.verifyCode"
              center
              clearable
              label="验证码"
              left-icon="lock"
              placeholder="验证码"
              :rules="[{ required: true, message: '请输入验证码' }]"
          >
            <template #button>
              <van-image
                  width="100"
                  height="30"
                  @click="refreshCode"
                  :src="imageCodeUrl"
              />
            </template>
          </van-field>
          <van-field
              v-model="loginModel.phoneCode"
              type="number"
              center
              clearable
              label="短信验证码"
              left-icon="lock"
              placeholder="短信验证码"
              :rules="[{ required: true, message: '请输入短信验证码' }]"
          >
            <template #button>
              <van-button v-if="second == 0" size="small" type="primary" native-type="button" @click="sendSmsCode">
                发送验证码
              </van-button>
              <van-button v-if="second > 0" disabled size="small" type="primary" native-type="button">
                {{ loginModel.phoneCodeMsg }}
              </van-button>
            </template>
          </van-field>
          <div style="margin: 16px;">
            <van-button round block type="info" native-type="submit">登录</van-button>
          </div>
        </van-form>
      </van-tab>
    </van-tabs>
    <van-row v-if="1==2">
      <van-col span="24">
        <div style="margin: 16px;">
          <van-button round block plain :icon="zwfwLogoUrl" type="info" native-type="button" @click="getZwfwLoginUrl">
            办事通登录
          </van-button>
        </div>
      </van-col>
    </van-row>
    <Loading :loding-txt="lodingTxt" :is-show="isShow"/>
  </div>
</template>

<script>
import {getCode, nameLogin, phoneLogin, getZwfwLoginUrl} from "../../api/auth"
import {getCookieKey, setKey} from '../../utils/cookie'
import Loading from "../../components/logding";

export default {
  name: "index",
  components: {Loading},
  data() {
    return {
      isShow: false,
      lodingTxt: '登录中...',
      uuid: '',
      imageCodeUrl: process.env.VUE_APP_IMAGE_CODE_URL,
      zwfwLogoUrl: require('../../assets/images/bst_logo.png'),
      second: 0,
      loginModel: {
        username: '',
        password: '',
        verifyCode: '',
        phone: '',
        phoneCode: '',
        phoneCodeMsg: '等待60秒',
        phoneButton: 'disabled'
      },
      toUrl: this.$route.query.to_url || '',
    }
  },
  created() {
    const uuid = getCookieKey(process.env.VUE_APP_TOKEN_KEY)
    if (uuid) {
      this.uuid = uuid
      this.imageCodeUrl = process.env.VUE_APP_IMAGE_CODE_URL + '?token=' + uuid
    }
  },
  methods: {
    /**
     * 账号密码登录
     */
    onNameLogin() {
      const that = this
      that.isShow = true
      that.lodingTxt = '登陆中...'
      nameLogin(that.loginModel.username, that.loginModel.password, that.loginModel.verifyCode)
          .then(response => {
            that.isShow = false
            if (response.code == process.env.VUE_APP_SUCCESS_CODE) {
              //把用户信息缓存
              this.$isLogin = true
              const data = response.data
              setKey(process.env.VUE_APP_USER_INFO, JSON.stringify(data))
              setKey(process.env.VUE_APP_H5_IS_LOGIN, true)
              setKey(process.env.VUE_APP_USER_ID, data.id)
              setKey(process.env.VUE_APP_USER_NAME, data.username)
              //是否已经注册
              setKey(process.env.VUE_APP_USER_IS_BIND, data.isBind)
              //绑定数量
              setKey(process.env.VUE_APP_USER_BIND_NUMBER, data.waterUserList.length || 0)
              //是否已绑定
              setKey(process.env.VUE_APP_USER_IS_REG, 1)
              //是否已实名
              setKey(process.env.VUE_APP_USER_IS_AUTH, data.isAuth)
              if (that.toUrl && response.extension && response.extension.ticket) {
                window.location.href = that.toUrl + '?ticket=' + response.extension.ticket
              } else {
                this.$router.push('../index')
              }
            } else {
              that.$toast(response.message)
            }
          }).catch(() => {
        that.isShow = false
        that.$toast('服务器错误，请稍后再试！')
      })
    },
    /**
     * 手机登录
     */
    onPhoneLogin() {
      const that = this
      that.isShow = true
      that.lodingTxt = '登陆中...'
      phoneLogin(that.loginModel.phone, that.loginModel.phoneCode)
          .then(response => {
            that.isShow = false
            if (response.code == process.env.VUE_APP_SUCCESS_CODE) {
              this.$isLogin = true
              //把用户信息缓存
              const data = response.data
              setKey(process.env.VUE_APP_USER_INFO, JSON.stringify(data))
              setKey(process.env.VUE_APP_H5_IS_LOGIN, true)
              setKey(process.env.VUE_APP_USER_ID, data.id)
              setKey(process.env.VUE_APP_USER_NAME, data.username)
              //是否已经注册
              setKey(process.env.VUE_APP_USER_IS_BIND, data.isBind)
              //绑定数量
              setKey(process.env.VUE_APP_USER_BIND_NUMBER, data.waterUserList.length || 0)
              //是否已绑定
              setKey(process.env.VUE_APP_USER_IS_REG, 1)
              //是否已实名
              setKey(process.env.VUE_APP_USER_IS_AUTH, data.isAuth)
              if (that.toUrl && response.extension && response.extension.ticket) {
                window.location.href = that.toUrl + '?ticket=' + response.extension.ticket
              } else {
                this.$router.push('../index')
              }
            } else {
              that.$toast(response.message)
            }
          }).catch(() => {
        that.isShow = false
        that.$toast('服务器错误，请稍后再试！')
      })
    },
    getZwfwLoginUrl() {
      const that = this
      that.isShow = true
      that.lodingTxt = '加载中...'
      getZwfwLoginUrl()
          .then(response => {
            that.isShow = false
            if (response.code == process.env.VUE_APP_SUCCESS_CODE) {
              window.location.href = response.data
            } else {
              that.$toast(response.message)
            }
          }).catch(() => {
        that.isShow = false
        that.$toast('服务器错误，请稍后再试！')
      })
    },
    onBack: function () {
      this.$router.go(-1);
    },
    /**
     * 验证码刷新
     */
    refreshCode() {
      this.imageCodeUrl = process.env.VUE_APP_IMAGE_CODE_URL + '?token=' + this.uuid + '&rnd=' + Math.random()
    },
    /**
     * 发送短信验证码
     */
    sendSmsCode() {
      const that = this
      if (that.loginModel.phone == '') {
        that.$toast('请输入手机号码')
        return
      }
      if (that.loginModel.verifyCode == '') {
        that.$toast('请输入验证码')
        return
      }
      that.isShow = true
      that.lodingTxt = '发送中...'
      getCode(that.loginModel.phone, that.loginModel.verifyCode).then(response => {
        that.isShow = false
        if (response.code == process.env.VUE_APP_SUCCESS_CODE) {
          that.$toast('发送短信验证码成功')
          that.second = 60
          const timer = setInterval(() => {
            that.second--;
            if (that.second > 0) {
              that.loginModel.phoneCodeMsg = '等待 ' + this.second + ' 秒'
              // toast.message = `倒计时 ${second} 秒`;
            } else {
              clearInterval(timer);
              // 手动清除
            }
          }, 1000);
        } else {
          that.$toast(response.message)
        }
      }).catch(() => {
        that.isShow = false
        that.$toast('服务器错误，请稍后再试！')
      })
    },
    validator(val) {
      return /1\d{10}/.test(val);
    }
  }
}
</script>

<style scoped>
</style>
